@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.main-nav {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: inherit;
  position: fixed;
  top: 0;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-bottom: 1px solid #8C8C8C;
}

.menu-link ul {
  display: flex;
  align-items: center;
  grid-gap: 5vw;
  gap: 5vw;
  height: 5vh;
  position: fixed;
  right: 12vw;
  top: 2vh;
}

.social-media ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.btn-grad {
  padding: 10px 25px;
  text-align: center;
  font-size: medium;
  color: black;            
  border-radius: 30px;
  border: 1px solid black; 
  cursor: pointer;
  font-weight: 400px;
  background: none; 
}

.btn-grad:hover {
  color: #000;
  text-decoration: none;
}

.social-media .hamburger-menu {
  display: none;
}

.line {
  width: 100%;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.1); 
  margin-top: 8%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.color-superscript {
  position: relative;
}

.color-superscript::after {
  content: "*";
  position: absolute;
  top: -0.1em; /* Adjust this value as needed */
  font-size: 0.7em; /* Adjust this value as needed */
  color: red; /* Set your desired color */
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100px;
  height: 40px;
  background-color: #333;
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.scroll-to-top:hover {
  opacity: 1;
}

.scroll-to-top.hide {
  display: none;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
}
.iframe-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}


/* hero section   */

.hero-section {
  padding-top:30vh;
  padding-left: 12vw;
  padding-bottom: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

input[type='radio'] {
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
  background: white;
  outline: 2px solid #333;
  border: 3px solid white;
  width: 16px;
  height: 16px;
  margin: 1vh;
  cursor: pointer;
}

input[type='radio']:checked {
  background: #333;
}

.hero-section p {
  padding: 4vh;
  font-size: 2rem;
  text-transform: capitalize;
  text-align: center;
}

.hero-section h1 {
  font-size: 44px;
  text-transform: capitalize;
  /* text-align: center; */
  font-weight: 700;
  padding-bottom: 10vh;
}

.footer h1 {
  font-family: "Inter";
  font-size: "2.00rem";
  font-weight: "500";
  color: "#FFFFFF";
  /* padding-top: "4vh"; */
}

.footer p {
  font-family: "Inter";
  font-size: "2.70rem";
  font-weight: "500";
  color: "#FFFFFF";
  /* padding-top: "4vh"; */
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
    padding-top: 3vh;
  }

  /* .menu-link ul {
    gap: 2vw;
    top: 12vh;
    background-color: white;
  } */

  .menu-link ul {
    grid-gap: 2vw;
    gap: 2vw;
    top: 12vh;
    background-color: white;
    width: 77vw;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
  }
  

  .logo,
  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color: white;
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 3rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 8rem;
    padding-top: 1vh;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 8rem;
    padding-top: 1vh;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }

  /* hero section  */

  .hero-section {
    padding-top: 20vh;
  }

  .hero-section h1 {
    font-size: 3.8rem;
  }

}
.mainout {
    padding-bottom: 5vh;
    padding-top: 20vh;
}

table {
    border-collapse: collapse;
    width: 90%;
  }
  
td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
.liShape {
    list-style-type: disc;
}

td > ul {
    padding-left: 2%;
}

p {
    padding-bottom: 2%;
}

@media (max-width: 520px) {
    .mainout {
        padding-top: 10vh;
    }
}
