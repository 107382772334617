/* Navbar.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.main-nav {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: inherit;
  position: fixed;
  top: 0;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-bottom: 1px solid #8C8C8C;
}

.menu-link ul {
  display: flex;
  align-items: center;
  gap: 5vw;
  height: 5vh;
  position: fixed;
  right: 12vw;
  top: 2vh;
}

.social-media ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.btn-grad {
  padding: 10px 25px;
  text-align: center;
  font-size: medium;
  color: black;            
  border-radius: 30px;
  border: 1px solid black; 
  cursor: pointer;
  font-weight: 400px;
  background: none; 
}

.btn-grad:hover {
  color: #000;
  text-decoration: none;
}

.social-media .hamburger-menu {
  display: none;
}

.line {
  width: 100%;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.1); 
  margin-top: 8%;
}
