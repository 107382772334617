.mainout {
    padding-bottom: 5vh;
    padding-top: 20vh;
}

table {
    border-collapse: collapse;
    width: 90%;
  }
  
td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
.liShape {
    list-style-type: disc;
}

td > ul {
    padding-left: 2%;
}

p {
    padding-bottom: 2%;
}

@media (max-width: 520px) {
    .mainout {
        padding-top: 10vh;
    }
}